/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap');

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

:root {
  --primary_medef: #6b59d3;
  --secondary_medef: grey;
  --white_medef: #fff;
  --text-clr_medef: #5b6475;
  --header-clr_medef: #25273d;
  --next-btn-hover_medef: #8577d2;
  --back-btn-hover_medef: #8b8c8c;
}

body {
  color: var(--text-clr_medef);
  font-size: 16px;
  position: relative;
  font-family: Barlow, sans-serif;
}

.form-check-input:checked {
  background-color: var(--primary_medef);
  border-color: var(--primary_medef);
}

p.required, label.required {
  &::after {
    content: '*';
    color: red;
    margin-left: 0.2rem;
  }
}
